import {
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { useLanguageContext } from './../../../context/locale.context'
import { useSharedContext } from './../../../context/shared.context'
import { ESITooltip } from './BotScoreTooltip/ESITooltip'

const TimelineRmBotScore = ({ score, isOpen, onClose, content }) => {
	const {
		state: { locale },
	} = useLanguageContext()

	const {
		state: { activity },
	} = useSharedContext()

	const isUserLead = user => {
		return (
			content?.includes(user?.operating_asset_first_name) &&
			user.activity_id === activity.activity_id
		)
	}

	const generateTooltip = (key, item) => {
		const rows = []
		let keyName = ''
		if (key === 'RPI') {
			keyName = 'rpi_values'
			const rpi_values = [
				{
					value: 'tot_customer_exposure',
					label: locale['Total Customer Exposure'],
				},
				{
					value: 'tot_industry_exposure',
					label: locale['Total Industry Exposure'],
				},
				{
					value: 'tot_on_before_time',
					label: locale['Total Deliveries Completed Ontime'],
				},
				{
					value: 'lead_index',
					label: locale['Lead Index'],
				},
				{
					value: 'rollback_index',
					label: locale['Rollback Index'],
				},
				{
					value: 'tot_deliveries_completed',
					label: locale['Total Deliveries Completed'],
				},
			]
			rows.push(...rpi_values)
		} else if (key === 'BPI') {
			keyName = 'bpi_values'
			const bpi_values = [
				{
					value: 'bpi_certificates_total',
					label: locale['Total Certificates'],
				},
				{
					value: 'bpi_qualification',
					label: locale['Qualification'],
				},
				{
					value: 'bpi_workex_total',
					label: locale['Total Work Experience'],
				},
				{
					value: 'bpi_certificates_relevant',
					label: locale['Relevant Certificates'],
				},
				{
					value: 'bpi_workex_relevant',
					label: locale['Relevant Work Experience'],
				},
			]
			rows.push(...bpi_values)
		}
		if (rows.length) {
			return (
				<Table variant='tooltipTable'>
					<Tbody>
						{rows.map((row, index) => (
							<Tr key={index}>
								<Td>{row.label}</Td>
								<Td>{Number(item?.[keyName]?.[row.value]).toFixed(2)}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			)
		}
		return null
	}
	if (!score || score.length === 0) return null

	return (
		<Drawer onClose={onClose} isOpen={isOpen} size='xl'>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>{locale['Explainable AI']}</DrawerHeader>
				<DrawerCloseButton />
				<DrawerBody>
					<Table
						variant='stickyHeaders'
						mt={5}
						colorScheme={localStorage.getItem('color')}
					>
						<Thead>
							<Tr>
								<Th>{locale['Asset Name']}</Th>
								<Th>{locale['Activity ID']}</Th>
								<Th>{locale['BPI']}</Th>
								<Th>{locale['RPI']}</Th>
								<Th>{locale['CPI']}</Th>
								<Th>{locale['ESI'] || 'ESI'} </Th>
								<Th>{locale['Availability']}</Th>
								<Th>{locale['IOF']}</Th>
							</Tr>
						</Thead>
						<Tbody>
							{score?.map((item, index) => (
								<Tr
									key={index}
									bg={
										isUserLead(item) &&
										'linear-gradient(225deg, #009b76e8 0%, #016f5485 100%)'
									}
								>
									<Td>{item.operating_asset_first_name}</Td>
									<Td>{item.activity_id}</Td>
									<Td cursor='default'>
										<Tooltip
											placement='auto'
											label={generateTooltip('BPI', item)}
											bg='white'
											borderRadius='5px'
											border='1px solid black'
										>
											{Number(item.BPI).toFixed(2)}
										</Tooltip>
									</Td>
									<Td cursor='default'>
										<Tooltip
											placement='auto'
											label={generateTooltip('RPI', item)}
											bg='white'
											borderRadius='5px'
											border='1px solid black'
										>
											{Number(item.RPI).toFixed(2)}
										</Tooltip>
									</Td>

									<Td>{Number(item.CPI).toFixed(2)}</Td>
									<Td cursor='default'>
										<ESITooltip item={item} />
									</Td>
									<Td>{item.availability_percent}</Td>
									<Td>{Number(item.IOF).toFixed(2)}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	)
}

export default TimelineRmBotScore
