import {
  Avatar,
  Box,
  Center,
  Flex,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { BsFileEarmark } from 'react-icons/bs'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { MdMoreVert, MdReply } from 'react-icons/md'
import CustomMenu from '../../../components/menu'
import {
  GA_CATEGORY_TIMELINE,
  sectionDetailedTrack,
} from '../../../containers/repository/repo.utils'
import { getShortName } from '../../../utils/common.util'
import { getUTCtoIST, timelineDateFormat } from '../../../utils/date.utils'

const ArticleUpdate = ({ timeline, asset_id, timelineReplyData }) => {
  const {
    operating_asset_first_name,
    data_entity_inline,
    datetime_log,
    log_asset_id,
    timeline_transaction_id,
  } = timeline

  const payload = JSON.parse(data_entity_inline)

  return (
    <Flex
      id={`reply_item${timeline_transaction_id}`}
      justifyContent="flex-start"
      alignItems="flex-start"
      my={4}
      flexDirection={log_asset_id === asset_id ? 'row-reverse' : 'row'}
    >
      <VStack alignItems="center">
        <Avatar name={operating_asset_first_name} size="md" />
        <Text fontSize="sm"> {operating_asset_first_name}</Text>
      </VStack>
      <VStack
        justifyContent="space-around"
        alignItems="center"
        bg="secondary"
        borderRadius="lg"
        minh="170px"
        maxh="250px"
        minW="200px"
        maxW="500px"
        mx={2}
        px={2}
        py={2}
        position="relative"
      >
        <CustomMenu
          icon={MdMoreVert}
          color="brand.800"
          borderRadius="md"
          boxShadow={0}
          aria-label="More"
          bg={'secondary'}
          size="sm"
          position="absolute"
          top="0px"
          right="0px"
          optionList={[
            {
              label: `Reply`,
              isMenu: true,
              icon: <Icon as={MdReply} w={5} h={5} color="brand.900" />,
              onClick: e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_TIMELINE,
                  action: 'Article Update',
                  label: 'Timeline Reply',
                })
                timelineReplyData({ ...timeline, title: payload.content })
              },
            },
          ]}
          selected={{}}
        />
        <Flex
          justifyContent="center"
          align="center"
          h="90px"
          w="90px"
          bg="white"
          borderRadius="lg"
        >
          <Center position="relative">
            <Box position="absolute" left="0" px={2} bg="white">
              <Text fontSize="12px" textTransform="uppercase">
                pdf
              </Text>
            </Box>
            <Icon as={BsFileEarmark} color="brand.900" h="4rem" w="4rem" />
          </Center>
        </Flex>
        <Text textAlign="center" fontSize="sm">
          {payload.subject}
          <br />
          <Link color="blue.400" target="_blank" href={payload.content}>
            Click Here <Icon as={FaExternalLinkAlt} mx="2px" />
          </Link>
        </Text>
        <Text fontSize="10px" alignSelf="flex-start" mb={0}>
          {timelineDateFormat(getUTCtoIST(datetime_log))}
        </Text>
      </VStack>
    </Flex>
  )
}

export default ArticleUpdate
