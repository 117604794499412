import {
  Avatar,
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { GrIntegration } from 'react-icons/gr';
import { MdMoreHoriz, MdMoreVert } from 'react-icons/md';
import { getShortName } from '../../../utils/common.util';
import { getUTCtoIST, timelineDateFormat } from '../../../utils/date.utils';

const TimelineIntegrationUpdate = ({ timeline, asset_id }) => {
  const {
    operating_asset_first_name,
    data_entity_inline,
    datetime_log,
    log_asset_id,
  } = timeline;
  const { subject = '', content = '' } = JSON.parse(data_entity_inline);

  return (
    <Flex
      className={`timeline-integration-update`}
      justifyContent="flex-start"
      alignItems="flex-start"
      my={4}
      flexDirection={log_asset_id === asset_id ? 'row-reverse' : 'row'}
    >
      <VStack
        alignItems="center"
        className={`timeline-integration-update-profile`}
      >
        <Avatar name={operating_asset_first_name} size="md" />
        <Text fontSize="sm"> {operating_asset_first_name}</Text>
      </VStack>
      <VStack
        className={`timeline-integration`}
        justifyContent="space-around"
        alignItems="center"
        bg="secondary"
        borderRadius="lg"
        minh="170px"
        maxh="250px"
        wordBreak="break-word"
        minW="200px"
        maxW="300px"
        mx={2}
        px={4}
        py={2}
        position="relative"
      >
        <Icon
          as={MdMoreVert}
          color="brand.800"
          cursor="pointer"
          w={6}
          h={6}
          position="absolute"
          top="2px"
          right="5px"
        />
        <HStack alignItems="flex-start">
          <Tooltip
            label="integration udpates"
            fontSize="sm"
            bg="secondary"
            color="black.700"
          >
            <Box borderRadius="md" p={2} bg="white" cursor="pointer">
              <Icon as={GrIntegration} w={6} h={6} color="brand.800" />
            </Box>
          </Tooltip>
          <Box textAlign="left" fontSize="14px">
            <Text textAlign="left" fontSize="14px" mb="10px">
              {subject}
            </Text>
            <Divider />
            <Text textAlign="left" fontSize="12px">
              {content}
            </Text>
          </Box>
        </HStack>

        <Text fontSize="10px" alignSelf="flex-start" mb={0}>
          {timelineDateFormat(getUTCtoIST(datetime_log))}
        </Text>
      </VStack>
    </Flex>
  );
};

export default TimelineIntegrationUpdate;
