import { Avatar, Box, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { MdEmail, MdMoreHoriz, MdMoreVert, MdReply } from 'react-icons/md'
import CustomMenu from '../../../components/menu'
import {
  GA_CATEGORY_TIMELINE,
  sectionDetailedTrack,
} from '../../../containers/repository/repo.utils'
import { getShortName } from '../../../utils/common.util'
import { getUTCtoIST, timelineDateFormat } from '../../../utils/date.utils'

const TimelineEmailUpdate = ({ timeline, asset_id, timelineReplyData }) => {
  const {
    operating_asset_first_name,
    data_entity_inline,
    datetime_log,
    log_asset_id,
    timeline_transaction_id,
  } = timeline
  const {
    reminder_email: {
      sender_name = '',
      form_approval = [],
      form_fill = [],
      receiver_name = '',
    },
  } = JSON.parse(data_entity_inline)

  let form_fill_data =
    !!form_fill &&
    Array.isArray(form_fill) &&
    form_fill
      .map(item => {
        return item.value
      })
      .join(', ')
  let form_approval_data =
    !!form_approval && Array.isArray(form_approval)
      ? form_approval
          .map(item => {
            return item.value
          })
          .join(', ')
      : !!form_approval.value
      ? form_approval.value
      : ''

  let content = `Email was sent to ${receiver_name} with fill request for the following forms - [${form_fill_data}] and review request for the following forms - [${form_approval_data}]`

  return (
    <Flex
      id={`reply_item${timeline_transaction_id}`}
      justifyContent="flex-start"
      alignItems="flex-start"
      my={4}
      flexDirection={log_asset_id === asset_id ? 'row-reverse' : 'row'}
    >
      <VStack alignItems="center">
        <Avatar name={operating_asset_first_name} size="md" />
        <Text fontSize="sm"> {operating_asset_first_name}</Text>
      </VStack>
      <VStack
        justifyContent="space-around"
        alignItems="center"
        bg="secondary"
        borderRadius="lg"
        minh="170px"
        maxh="250px"
        wordBreak="break-word"
        minW="200px"
        maxW="300px"
        mx={2}
        px={4}
        py={2}
        position="relative"
      >
        <CustomMenu
          icon={MdMoreVert}
          color="brand.800"
          borderRadius="md"
          boxShadow={0}
          aria-label="More"
          bg={'secondary'}
          size="sm"
          position="absolute"
          top="0px"
          right="0px"
          optionList={[
            {
              label: `Reply`,
              isMenu: true,
              icon: <Icon as={MdReply} w={5} h={5} color="brand.900" />,
              onClick: e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_TIMELINE,
                  action: 'Timeline Email Update',
                  label: 'Reply',
                })
                timelineReplyData({ ...timeline, title: content })
              },
            },
          ]}
          selected={{}}
        />
        <HStack alignItems="flex-start">
          <Box borderRadius="md" p={2} bg="white">
            <Icon as={MdEmail} w={6} h={6} color="brand.800" />
          </Box>
          <Text textAlign="left" fontSize="14px">
            {content}
          </Text>
        </HStack>

        <Text fontSize="10px" alignSelf="flex-start" mb={0}>
          {timelineDateFormat(getUTCtoIST(datetime_log))}
        </Text>
      </VStack>
    </Flex>
  )
}

export default TimelineEmailUpdate
