import { Table, Tbody, Td, Tr, Tooltip } from '@chakra-ui/react'

export const ESITooltip = ({ item }) => {
	const generateTooltip = () => {
		let keyName = 'esi_skillset'
		if (!item[keyName] || Object.keys(item[keyName]).length === 0) return null

		return (
			<Table variant='tooltipTable'>
				<Tbody>
					{Object.entries(item[keyName]).map(([key, value]) => {
						return (
							<Tr key={key}>
								<Td>{key}</Td>
								<Td>{value}</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
		)
	}

	const generateLabel = () => {
		if (!item.esi) return 'N/A'
		return Number(item.esi).toFixed(2)
	}

	return (
		<>
			<Tooltip
				placement='auto'
				label={generateTooltip()}
				bg='white'
				borderRadius='5px'
				border='1px solid black'
			>
				{generateLabel()}
			</Tooltip>
		</>
	)
}
