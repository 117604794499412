import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

const SystemMessage = ({ timeline }) => {
  const {
    operating_asset_first_name,
    timeline_stream_type_id,
    data_entity_inline,
    log_asset_id,
    asset_id,
    log_asset_first_name,
    timeline_stream_type_name,
  } = timeline
  const payload = JSON.parse(data_entity_inline)
  let content = ''
  if (!![702, 27004].includes(timeline_stream_type_id)) {
    if (asset_id === log_asset_id) {
      content = payload.content
    } else {
      // NOTE: also works for [100, 35532].includes(log_asset_id)
      content = `${log_asset_first_name} added ${operating_asset_first_name} to this conversation`
    }
  } else if (!![2].includes(timeline_stream_type_id)) {
    if (asset_id === log_asset_id) {
      content = `${log_asset_first_name} joined this conversation`
    } else {
      // NOTE: also works for [100, 35532].includes(log_asset_id)
      content = `${log_asset_first_name} added ${operating_asset_first_name} to this conversation`
    }
  } else if (!![707, 3].includes(timeline_stream_type_id)) {
    if (asset_id === log_asset_id) {
      content = `${log_asset_first_name} left this conversation`
    } else {
      // NOTE: also works for [100, 35532].includes(log_asset_id)
      content = `${log_asset_first_name} removed ${operating_asset_first_name} from this conversation`
    }
  } else if (
    !![325].includes(timeline_stream_type_id) &&
    payload.hasOwnProperty('participant_added')
  ) {
    content = payload.content
  } else if (!![736].includes(timeline_stream_type_id)) {
    content = `${operating_asset_first_name} has declined to be lead`
  } else {
    content = payload.content
    if (content?.content) {
      content = content.content
    }
  }

  return (
    <Flex w="100%" justifyContent="center" alignItems="center" my={2}>
      <Text fontSize="12px" color="brand.800">
        {content}
      </Text>
    </Flex>
  )
}

export default SystemMessage
