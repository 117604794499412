import { Box, Text, Tag, Avatar } from '@chakra-ui/react'
import React from 'react'
import TimelineAttachment from './attachement'
import TimelineRmBotScore from './bot-score'
import TimelineEmailUpdate from './email-update'
import FormTimelineUpdate from './form-update'
import Message from './message'
import SystemMessage from './system-message'
import TimelineIntegrationUpdate from './integration-update'
import { TIMELINE_MESSAGE_TYPE } from '../util'
import ArticleUpdate from './article-update'

const TimelineComponents = ({ timeline, filter = 'ALL', ...rest }) => {
  const {
    operating_asset_first_name,
    timeline_stream_type_id,
    timeline_transaction_id,
    timeline_stream_type_name,
    data_entity_inline,
    data_form_name,
  } = timeline
  const payload = JSON.parse(data_entity_inline)
  if (
    !![705, 713, 726].includes(timeline_stream_type_id) &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.DATA_MANAGEMENT) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.DEFAULT))
  ) {
    // form updates
    return <FormTimelineUpdate {...rest} timeline={timeline} />
  }
  if (
    !![718].includes(timeline_stream_type_id) &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.COMMENTS) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.DEFAULT))
  ) {
    if (payload.hasOwnProperty('attachments') && !!payload.attachments.length) {
      return <TimelineAttachment {...rest} timeline={timeline} />
    } else {
      return <Message {...rest} timeline={timeline} />
    }
  }
  if (
    !![325].includes(timeline_stream_type_id) &&
    payload.hasOwnProperty('attachments') &&
    !!payload.attachments.length &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.MEDIA) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.DEFAULT))
  ) {
    return <TimelineAttachment {...rest} timeline={timeline} />
  } else if (
    !![325, 509].includes(timeline_stream_type_id) &&
    payload.hasOwnProperty('attachments') &&
    !payload.attachments.length &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.COMMENTS) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.DEFAULT))
  ) {
    return <Message {...rest} timeline={timeline} />
  }

  if (
    !![715].includes(timeline_stream_type_id) &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.COMMENTS) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.DEFAULT))
  ) {
    return <TimelineEmailUpdate {...rest} timeline={timeline} />
  }

  if (
    !![711, 704, 702, 717, 707, 325, 2, 734, 509, 27004, 3, 736, 3101].includes(
      timeline_stream_type_id
    ) &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.SYSTEM_MESSAGE) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL))
  ) {
    // system message
    return <SystemMessage {...rest} timeline={timeline} />
  }

  /**
   * TODO:
   * 27008 - Debit from Account
   * 27007 - Credit from Account
   */

  if (
    !![723].includes(timeline_stream_type_id) &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.COMMENTS) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.DEFAULT))
  ) {
    return <ArticleUpdate timeline={timeline} {...rest} />
  }

  if (
    !![730].includes(timeline_stream_type_id) &&
    (filter.includes(TIMELINE_MESSAGE_TYPE.INTEGRATION) ||
      filter.includes(TIMELINE_MESSAGE_TYPE.ALL))
  ) {
    return <TimelineIntegrationUpdate timeline={timeline} {...rest} />
  }

  if (
    !![723, 724, 27007, 27008].includes(timeline_stream_type_id) &&
    filter.includes(TIMELINE_MESSAGE_TYPE.ALL)
  ) {
    return (
      <Box className={`timeline-message-all-type ${timeline_transaction_id}`}>
        <Box key={timeline_transaction_id}>
          <Avatar name={operating_asset_first_name} />
          <Tag>
            {timeline_stream_type_id} - {timeline_stream_type_name}
          </Tag>
          <Text>{payload.subject}</Text>
        </Box>
      </Box>
    )
  }

  return <></>
}

export default TimelineComponents
