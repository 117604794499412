/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Text,
  VStack,
  FormHelperText,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  MdMoreVert,
  MdReply,
  MdFileDownload,
  MdMoreHoriz,
} from 'react-icons/md'
import CustomMenu from '../../../components/menu'
import { BsFileEarmark } from 'react-icons/bs'
import { FiDownloadCloud } from 'react-icons/fi'
import { ImBlocked } from 'react-icons/im'
import { getUTCtoIST, timelineDateFormat } from '../../../utils/date.utils'
import { getShortName } from '../../../utils/common.util'
import { downloadAwsFile } from '../../../utils/aws.util'
import { SingleSelectionList } from '../../form/components'
import { useSearchWorkflowByTags } from '../../../hooks/form.hooks'
import { useAuthContext } from '../../../context/auth.context'
import {
  useAddTimelineUpdate,
  useLoadActivityParticipant,
  useLoadActivityListV1,
} from '../../../hooks/activity.hooks'

import { useEffect } from 'react'

import Select from '../../chakra-react-select'
import {
  GA_CATEGORY_TIMELINE,
  sectionDetailedTrack,
} from '../../../containers/repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'
// TODO: Implement the congnito based document download.

const TimelineAttachment = ({ timeline, asset_id, timelineReplyData }) => {
  const {
    operating_asset_first_name,
    data_entity_inline,
    datetime_log,
    log_asset_id,
    timeline_transaction_id,
  } = timeline
  const {
    state: { locale },
  } = useLanguageContext()
  const [overlayOpacity, setoverlayOpacity] = useState(0)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSelectParticipant, setSelectParticipant] = useState(false)
  const { mutate } = useLoadActivityListV1()
  const { mutate: participantList } = useLoadActivityParticipant()
  const [selectedParticipant, setSelectedParticipant] = useState([])
  const [options, setOptions] = useState([])
  const [value, setValue] = useState([])
  const [error, setError] = useState([])
  const [data, setdata] = useState([])
  const [participentError, setparticipentError] = useState('')
  const toast = useToast({
    duration: 1000,
  })
  const { mutate: mutateUpdateAddTimeline } = useAddTimelineUpdate()

  const {
    state: { authData },
  } = useAuthContext()

  const { organization_flag_enable_attachment_access_control } = authData
  const isMobileView = localStorage.getItem("IS_MOBILE_VIEW")

  const {
    attachments,
    doc_access_participants,
    flag_attachment_access_restricted,
  } = JSON.parse(data_entity_inline)
  let filename = ''
  let extension = ''
  let is_enable = !!doc_access_participants && doc_access_participants?.length
  let download_access = !!doc_access_participants
    ? doc_access_participants.map(s => s.asset_id)
    : []

  const loadParticipants = e => {
    participantList(
      { activity_id: e.details.activity_id },
      {
        onSuccess: async data => {
          let finalData = []
          if (data.list && data.list.length > 0) {
            finalData = data.list.filter(val => val.asset_id !== asset_id)
          }
          setdata(finalData || [])
        },
      }
    )
  }

  const isHaveAccess = () => {
    let allow = true
    let org_access = flag_attachment_access_restricted
    if (org_access && org_access === 1 && download_access.length > 0) {
      allow = download_access.includes(asset_id)
    } else if (org_access && org_access === 2 && download_access.length > 0) {
      allow = download_access.includes(asset_id) ? false : true
    }
    // (is_enable && download_access.includes(asset_id)) || !is_enable
    return allow
  }

  attachments.forEach(i => {
    if (!!i) {
      const indexImage = i.lastIndexOf('/') + 1
      filename = i.toString().substr(indexImage)
      extension = filename.split('.').pop()
    }
  })

  const onDownload = () => {
    if (!!attachments[0]) downloadAwsFile(attachments[0])
  }

  useEffect(() => {
    mutate(
      {
        flag: 0,
        is_active: 0,
        is_due: 0,
        is_search: 0,
        is_unread: 0,
        limit_value: 30,
        page_limit: 30,
        page_start: 0,
        queue_id: 0,
        search_string: '',
        sort_flag: 4,
        start_from: 0,
        status_type_id: 0,
      },
      {
        onSuccess: res => {
          let data = res.list.map(
            ({
              activity_cuid_1,
              activity_cuid_2,
              activity_cuid_3,
              activity_id,
              activity_title,
              operating_asset_first_name,
              activity_type_category_id,
              activity_type_id,
            }) => ({
              details: {
                activity_cuid_1,
                activity_cuid_2,
                activity_cuid_3,
                operating_asset_first_name,
                activity_id,
                activity_type_category_id,
                activity_type_id,
              },
              label: activity_title,
              value: `${activity_id + '|' + activity_title}`,
            })
          )
          setOptions([...data])
        },
      }
    )
  }, [isModalOpen])

  const onSubmit = () => {
    const {
      details: {
        operating_asset_first_name,
        activity_id,
        activity_type_category_id,
        activity_type_id,
      },
    } = value
    let payload = {
      content: [],
      subject: attachments.toString(),
      mail_body: attachments.toString(),
      attachments: [attachments.toString()],
      activity_reference: [],
      asset_reference: [],
      form_approval_field_reference: [],
      is_reply: 0,
      flag_attachment_access_restricted: organization_flag_enable_attachment_access_control || 0,
      doc_access_participants: [
        ...selectedParticipant.map(p => ({
          asset_id: p.value,
          asset_name: p.label,
        })),
        organization_flag_enable_attachment_access_control === 1 && {
          asset_id: asset_id,
          asset_name: operating_asset_first_name,
        },
      ],
    }
    mutateUpdateAddTimeline(
      {
        activity_id,
        // data_entity_inline: JSON.stringify(payload),
        activity_timeline_collection: JSON.stringify(payload),
      },
      {
        onSuccess: async data => {
          setModalOpen(false)
          setSelectParticipant(false)
        },
        onError: async err => setError(err),
      }
    )
  }

  const onActivityChange = value => {
    setValue(value)
    loadParticipants(value)
  }
  const inputChange = e => {
    mutate(
      {
        flag: 0,
        is_active: 0,
        is_due: 0,
        is_search: 1,
        is_unread: 0,
        limit_value: 30,
        page_limit: 30,
        page_start: 0,
        queue_id: 0,
        search_string: e,
        sort_flag: 4,
        start_from: 0,
        status_type_id: 0,
      },
      {
        onSuccess: res => {
          let data = res.list.map(
            ({
              activity_cuid_1,
              activity_cuid_2,
              activity_cuid_3,
              activity_id,
              activity_title,
              operating_asset_first_name,
              activity_type_category_id,
              activity_type_id,
            }) => ({
              details: {
                activity_cuid_1,
                activity_cuid_2,
                activity_cuid_3,
                operating_asset_first_name,
                activity_id,
                activity_type_category_id,
                activity_type_id,
              },
              label: activity_title,
              value: `${activity_id + '|' + activity_title}`,
            })
          )
          setOptions([...data])
        },
      }
    )
  }

  return (
    <Flex
      id={`reply_item${timeline_transaction_id}`}
      position="relative"
      justifyContent="flex-start"
      alignItems="flex-start"
      my={4}
      flexDirection={log_asset_id === asset_id ? 'row-reverse' : 'row'}
    >
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false)
          setSelectParticipant(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{locale['Select Activity to share']}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Box w="full">
                <Select
                  options={options}
                  placeholder={locale['Select Activity to share']}
                  borderRadius="md"
                  onChange={onActivityChange}
                  value={value}
                  size="sm"
                  maxMenuHeight={300}
                  minMenuHeight={140}
                  onInputChange={e => inputChange(e)}
                />
                {organization_flag_enable_attachment_access_control > 0 &&
                  !!isSelectParticipant && (
                    <FormControl
                      className={`field-item-edit-form`}
                      boxShadow="md"
                      borderRadius="md"
                      id="field-edit"
                      p={2}
                      mb={2}
                      isInvalid={!!participentError}
                    >
                      <FormLabel
                        className={`field-item-form-label`}
                        fontWeight="400"
                        fontSize="sm"
                        my={1}
                        color="brand.800"
                      >
                        {locale['Select participants who']}{' '}
                        {organization_flag_enable_attachment_access_control ===
                        1
                          ? locale['can']
                          : locale["can't"]}{' '}
                        {locale['access attachments']}{' '}
                      </FormLabel>
                      <Select
                        isMulti={true}
                        options={
                          data.length > 0
                            ? (data || [])?.map(d => ({
                                label: d.operating_asset_first_name,
                                value: d.asset_id,
                              }))
                            : []
                        }
                        onChange={e => setSelectedParticipant(e)}
                        placeholder={locale['Select participant']}
                        borderRadius="md"
                        value={selectedParticipant}
                        size="xs"
                        menuPlacement={'top'}
                      />
                      {!!participentError ? (
                        <FormErrorMessage>
                          {locale['Please select participants']}
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  )}
              </Box>
            </Center>
          </ModalBody>
          <ModalFooter>
            {!!error && (
              <FormHelperText color="red.300" fontWeight="400" fontSize="12px">
                {error}
              </FormHelperText>
            )}
            <Button
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              mr={3}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_TIMELINE,
                  action: 'Timeline Attachment',
                  label: 'Submit',
                })
                onSubmit()
              }}
            >
              {locale['Submit']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack alignItems="center">
        <Avatar name={operating_asset_first_name} size="md" />
        <Text fontSize="sm"> {operating_asset_first_name}</Text>
      </VStack>
      <VStack
        justifyContent="space-around"
        alignItems="center"
        bg="secondary"
        borderRadius="lg"
        minh="170px"
        maxh="250px"
        minW="200px"
        maxW="250px"
        mx={2}
        px={2}
        py={2}
        position="relative"
        zIndex="0"
      >
        <CustomMenu
          icon={MdMoreVert}
          color="brand.800"
          borderRadius="md"
          boxShadow={0}
          aria-label="More"
          bg={'secondary'}
          size="sm"
          position="absolute"
          top="0px"
          right="0px"
          optionList={[
            {
              label: locale['Reply'],
              isMenu: true,
              icon: <Icon as={MdReply} w={5} h={5} color="brand.900" />,
              onClick: e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_TIMELINE,
                  action: 'Timeline Attachment',
                  label: 'Reply',
                })
                timelineReplyData({ ...timeline, title: filename })
              },
            },
            !!isHaveAccess() && !isMobileView && {
              label: locale['Share with activity'],
              isMenu: true,
              icon: <Icon as={MdReply} w={5} h={5} color="brand.900" />,
              onClick: e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_TIMELINE,
                  action: 'Timeline Attachment',
                  label: 'Open Submit Modal',
                })
                setModalOpen(true)
                // timelineReplyData({ ...timeline, title: filename });
              },
            },
            !!isHaveAccess() && !isMobileView &&
              organization_flag_enable_attachment_access_control > 0 && {
                label: locale['Share access based activity'],
                isMenu: true,
                icon: <Icon as={MdReply} w={5} h={5} color="brand.900" />,
                onClick: e => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_TIMELINE,
                    action: 'Timeline Attachment',
                    label: 'Share Access Based Activity',
                  })
                  setModalOpen(true)
                  setSelectParticipant(true)
                  // timelineReplyData({ ...timeline, title: filename });
                },
              },
          ].filter(Boolean)}
          selected={{}}
        />
        <Flex
          justifyContent="center"
          align="center"
          h="90px"
          w="90px"
          bg="white"
          onMouseOver={() => {
            setoverlayOpacity(0.8)
          }}
          onMouseLeave={() => {
            setoverlayOpacity(0)
          }}
          borderRadius="lg"
        >
          <Center position="relative">
            {isHaveAccess() ? (
              <Box
                position="absolute"
                top="-13px"
                bottom="0"
                left="-13px"
                right="0"
                h="90px"
                w="90px"
                opacity={overlayOpacity}
                transition=".3s ease"
                bg={localStorage.getItem('color')}
                display="flex"
                borderRadius="lg"
                px={2}
                py={2}
                alignItems="center"
                justifyContent="center"
                zIndex="10"
                cursor="pointer"
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_TIMELINE,
                    action: 'Timeline Attachment',
                    label: 'AWS Download',
                  })
                  onDownload()
                }}
              >
                <Icon as={FiDownloadCloud} color="#FFFFFF" h={12} w={12} />
              </Box>
            ) : (
              <Box
                position="absolute"
                top="-13px"
                bottom="0"
                left="-13px"
                right="0"
                h="90px"
                w="90px"
                opacity={overlayOpacity}
                transition=".3s ease"
                bg="red.200"
                display="flex"
                borderRadius="lg"
                px={2}
                py={2}
                alignItems="center"
                justifyContent="center"
                zIndex="10"
                cursor="pointer"
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_TIMELINE,
                    action: 'Timeline Attachment',
                    label: 'Download Restricted Warning',
                  })
                  toast({
                    title:
                      'The file you are trying to download is restricted and unavailable for downloading.',
                    status: 'error',
                  })
                }}
              >
                <Icon as={ImBlocked} color="red.300" h={12} w={12} />
              </Box>
            )}
            <Box position="absolute" left="0" px={2} bg="white">
              <Text fontSize="12px" textTransform="uppercase">
                {' '}
                {extension}{' '}
              </Text>
            </Box>
            <Icon as={BsFileEarmark} color="brand.900" h="4rem" w="4rem" />
          </Center>
        </Flex>
        <Text
          textAlign="center"
          wordBreak="break-all"
          fontSize="sm"
        >{`${filename}`}</Text>
        <Text fontSize="10px" alignSelf="flex-start" mb={0}>
          {timelineDateFormat(getUTCtoIST(datetime_log))}
        </Text>
      </VStack>
    </Flex>
  )
}

export default TimelineAttachment
