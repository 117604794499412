import {
	Divider,
	HStack,
	Avatar,
	Center,
	Flex,
	Icon,
	Text,
	VStack,
	useDisclosure,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react'
import React from 'react'
import { FaFileAlt } from 'react-icons/fa'
import { MdMoreHoriz, MdReply, MdMoreVert } from 'react-icons/md'
import CustomMenu from '../../../components/menu'
import { getShortName } from '../../../utils/common.util'
import { getUTCtoIST, timelineDateFormat } from '../../../utils/date.utils'
import moment from 'moment'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_TIMELINE,
// 	sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'

const FORM_PREFIX = {
	705: 'has been submitted',
	713: 'has been updated',
	726: 'has been resubmitted',
}

const FormTimelineUpdate = ({
	timeline,
	asset_id,
	asset_flag_super_admin,
	onFormSelection = () => {},
	timelineReplyData,
	isUserHaveAccess = false,
}) => {
	const {
		isOpen: isOpenNoAccess,
		onOpen: onOpenNoAccess,
		onClose: onCloseNoAccess,
	} = useDisclosure()

	const cancelRef = React.useRef()

	const {
		operating_asset_first_name,
		timeline_stream_type_id,
		datetime_log,
		data_form_name,
		log_asset_id,
		data_entity_inline,
		data_form_transaction_id,
		timeline_transaction_id,
		operating_asset_id,
	} = timeline

	const {
		form_submitted,
		form_field_preview_enabled,
		content,
		subject,
		form_flag_enable_data_secrecy = 0,
	} = JSON.parse(data_entity_inline) || {}

	const checkFormAccess = () => {
		if (form_flag_enable_data_secrecy === 1) {
			return (
				log_asset_id === asset_id ||
				asset_flag_super_admin === 1 ||
				isUserHaveAccess
			)
		} else {
			return true
		}
	}

	let data_form_id
	let submitted_data = []
	if (typeof form_submitted === 'string') {
		let field = JSON.parse(form_submitted)[0]
		data_form_id = field.form_id
		submitted_data = JSON.parse(form_submitted)
	} else {
		let field = form_submitted[0] || {}
		data_form_id = field.form_id
		submitted_data = form_submitted
	}

	const getValue = data => {
		if (data && typeof data === 'string' && data.includes('s3.ap-south-1')) {
			return data.replace('"', '').split('/').reverse()[0]
		} else {
			try {
				let json_data = typeof data === 'string' ? JSON.parse(data) : data
				let d = Array.isArray(json_data)
					? json_data
							.map(s => s.operating_asset_first_name || s.lov_name)
							.join(', ')
					: JSON.stringify(json_data)
				return d
			} catch (e) {
				return data
			}
		}
	}

	const htmlDecode = content => {
		var rawMarkup = escape(content).replace(/%0A/g, '<br/>')
		return { __html: unescape(rawMarkup) }
	}

	return (
		<Flex
			className={`timeline-attachment-message-container`}
			id={`reply_item${timeline_transaction_id}`}
			justifyContent='flex-start'
			alignItems='flex-start'
			my={4}
			flexDirection={log_asset_id === asset_id ? 'row-reverse' : 'row'}
		>
			<VStack
				alignItems='center'
				className={`timeline-attachment-message-profile`}
			>
				<Avatar name={operating_asset_first_name} size='md' />
				<Text fontSize='sm'> {operating_asset_first_name}</Text>
			</VStack>
			<VStack
				className={`timeline-attachment-message-file-details`}
				justifyContent='space-around'
				alignItems='center'
				bg={checkFormAccess() ? 'secondary' : 'gray.200'}
				borderRadius='lg'
				minh='170px'
				maxh='250px'
				minW='200px'
				maxW='500px'
				mx={2}
				px={2}
				py={2}
				position='relative'
			>
				<CustomMenu
					className={`timeline-attachment-message-menu`}
					icon={MdMoreVert}
					color='brand.800'
					borderRadius='md'
					boxShadow={0}
					aria-label='More'
					bg={'secondary'}
					size='sm'
					position='absolute'
					top='0px'
					right='0px'
					optionList={[
						{
							label: `Reply`,
							isMenu: true,
							icon: <Icon as={MdReply} w={5} h={5} color='brand.900' />,
							onClick: e => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_TIMELINE,
								// 	action: 'Form Timeline Update',
								// 	label: 'Reply',
								// })
								timelineReplyData({
									...timeline,
									title: `${data_form_name} ${FORM_PREFIX[timeline_stream_type_id]}`,
								})
							},
						},
					]}
					selected={{}}
				/>
				<HStack>
					<Flex
						className={`timeline-attachment-message-file-details`}
						mx={5}
						alignSelf='flex-start'
						justifyContent='center'
						align='center'
						h='90px'
						w='90px'
						bg='white'
						borderRadius='lg'
						cursor='pointer'
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_TIMELINE,
							// 	action: 'Form Timeline Update',
							// 	label: 'Open Form View',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'File Timeline',
							// 	buttonName: 'Open Form View',
							// })
							if (!!data_form_id && checkFormAccess()) {
								onFormSelection({
									form_id: data_form_id,
									form_name: data_form_name,
									form_transaction_id: data_form_transaction_id,
									submitted_data: submitted_data,
									is_from_timeline: true,
								})
							} else {
								onOpenNoAccess()
							}
						}}
					>
						<Center>
							<Icon as={FaFileAlt} color='brand.800' h='4rem' w='4rem' />
						</Center>
					</Flex>
					<VStack
						wordBreak='break-all'
						alignSelf='flex-start'
						alignItems='flex-start'
						flex={1}
						pr={10}
					>
						<Text
							fontSize='sm'
							fontWeight='600'
						>{`${data_form_name} ${FORM_PREFIX[timeline_stream_type_id]}`}</Text>

						<Text
							// textAlign="left"
							fontSize='12px'
							dangerouslySetInnerHTML={htmlDecode(getValue(content))}
						/>
						{form_field_preview_enabled && form_field_preview_enabled.length ? (
							<VStack alignItems='flex-start' w='full'>
								<Divider />
								{form_field_preview_enabled.map((d, i) => {
									var hours = 0
									var minutes = 0
									if (
										!!d.field_value &&
										typeof d.field_value === 'string' &&
										d.field_value.includes('start_date_time') &&
										d.field_value.includes('end_date_time')
									) {
										d['field_value'] = JSON.parse(d.field_value)
									}
									hours =
										!!d.field_value && !!d.field_value.duration
											? Math.floor(Number(d.field_value.duration) / 60)
											: 0
									minutes =
										!!d.field_value && !!d.field_value.duration
											? Number(d.field_value.duration) % 60
											: 0
									let f_value =
										typeof d.field_value === 'object'
											? JSON.stringify(d.field_value)
											: d.field_value
									let text = escape(f_value).replace(/%5Ct/g, ' ')
									d['field_value'] =
										!!d.field_value && !!d.field_value.duration
											? d.field_value
											: unescape(text)
									let leaveParse = data => {
										try {
											let val = JSON.parse(data)
											if (!!val.leave_data && Array.isArray(val.leave_data)) {
												let valArr = Array.isArray(val.leave_data)
													? val.leave_data.filter(Boolean)
													: []
												return valArr
													.map(
														l =>
															l.leave_type_name +
															' - ' +
															l.leave_applied +
															' days'
													)
													.join(', \n')
											} else {
												return data
											}
										} catch (err) {
											return data
										}
									}
									d['field_value'] = leaveParse(d.field_value)
									return (
										<Text fontWeight='700' key={i}>
											{d.field_name} :{' '}
											{!!d.field_value && !!d.field_value.duration ? (
												<>
													<Text fontWeight='400'>
														{`Start Datetime: `}
														<Text as='span' fontWeight='400' color='brand.800'>
															{`${moment(d.field_value.start_date_time).format(
																'DD/MM/YYYY hh:mm A'
															)}`}
														</Text>
													</Text>
													<Text fontWeight='400'>
														{`Duration: `}
														<Text as='span' fontWeight='400' color='brand.800'>
															{`${hours} Hr ${minutes} Min`}
														</Text>
													</Text>
													<Text fontWeight='400'>
														{`End Datetime: `}
														<Text as='span' fontWeight='400' color='brand.800'>
															{`${moment(d.field_value.end_date_time).format(
																'DD/MM/YYYY hh:mm A'
															)}`}
														</Text>
													</Text>
												</>
											) : (
												<Text
													as='span'
													fontWeight='400'
													color='brand.800'
													wordBreak='break-word'
													dangerouslySetInnerHTML={htmlDecode(
														getValue(d.field_value)
													)}
												/>
											)}
										</Text>
									)
								})}
							</VStack>
						) : null}
					</VStack>
				</HStack>
				<Text
					className={`timeline-attachment-message-text-time-log`}
					fontSize='10px'
					alignSelf='flex-start'
					mb={0}
				>
					{timelineDateFormat(getUTCtoIST(datetime_log))}
				</Text>
			</VStack>
			<AlertDialog
				isOpen={isOpenNoAccess}
				leastDestructiveRef={cancelRef}
				onClose={onCloseNoAccess}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{'Form Access Denied'}
						</AlertDialogHeader>

						<AlertDialogBody>
							{'Form access denied. Please contact admin'}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									onCloseNoAccess()
								}}
							>
								{'Ok'}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Flex>
	)
}

export default FormTimelineUpdate
