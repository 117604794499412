import {
	Avatar,
	Flex,
	Icon,
	Spacer,
	Text,
	VStack,
	Box,
	useDisclosure,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdMoreVert, MdReply } from 'react-icons/md'
import CustomMenu from '../../../components/menu'
// import {
//   dataLayerTagManager,
//   GA_CATEGORY_TIMELINE,
//   sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import { parseJSONString } from '../../../utils/common.util'
import { getUTCtoIST, timelineDateFormat } from '../../../utils/date.utils'
import TimelineRmBotScore from './bot-score'
import { convertPound, convertUnicode } from '../../../utils/linkInText.utils'

const Message = ({ timeline, asset_id, timelineReplyData, timelineScroll }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const {
		operating_asset_first_name,
		data_entity_inline,
		datetime_log,
		log_asset_id,
		timeline_transaction_id,
		timeline_stream_type_name,
	} = timeline
	const payload = JSON.parse(data_entity_inline)
	let content = payload?.content
	let is_reply = payload?.is_reply
	let replying_to = !!is_reply && is_reply === 1 ? payload?.replying_to : {}
	if (content?.content) {
		content = content.content
	}

	const rmBotScores = parseJSONString(payload?.rm_bot_scores) || []

	const htmlDecode = content => {
		var rawMarkup = escape(content).replace(/%0A/g, '<br/>')
		return { __html: unescape(rawMarkup) }
	}
	return (
		<>
			{rmBotScores?.length > 0 ? (
				<TimelineRmBotScore
					score={rmBotScores}
					isOpen={isOpen}
					onClose={onClose}
					content={content}
				/>
			) : null}

			<Flex
				className={`timeline-message-component`}
				id={`reply_item${timeline_transaction_id}`}
				justifyContent='flex-start'
				alignItems='flex-start'
				my={4}
				flexDirection={log_asset_id === asset_id ? 'row-reverse' : 'row'}
			>
				<VStack alignItems='center' className={`message-component-profile`}>
					<Avatar name={operating_asset_first_name} size='md' />
					<Text fontSize='sm'> {operating_asset_first_name}</Text>
				</VStack>
				<VStack
					className={`message-component-custom-menu-container`}
					justifyContent='space-around'
					alignItems='center'
					bg='secondary'
					borderRadius='lg'
					minh='170px'
					maxh='250px'
					wordBreak='break-word'
					minW='300px'
					maxW='500px'
					mx={2}
					px={4}
					py={2}
					position='relative'
				>
					<CustomMenu
						icon={MdMoreVert}
						bg={'secondary'}
						color='brand.800'
						borderRadius='md'
						boxShadow={0}
						aria-label='More'
						size='sm'
						position='absolute'
						top='0px'
						right='0px'
						optionList={[
							{
								label: `Reply`,
								isMenu: true,
								icon: <Icon as={MdReply} w={5} h={5} color='brand.900' />,
								onClick: e => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_TIMELINE,
									//   action: 'Message',
									//   label: 'Reply',
									// })
									// dataLayerTagManager('button_click', {
									//   viewName: 'File Timeline',
									//   buttonName: 'Reply',
									// })
									timelineReplyData({ ...timeline, title: content })
								},
							},
						]}
						selected={{}}
					/>
					<Spacer />
					{is_reply === 1 && (
						<Box
							className='reply-qoute'
							whiteSpace={'pre-line'}
							onClick={() => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_TIMELINE,
								//   action: 'Message',
								//   label: 'Timeline Scroll',
								// })
								timelineScroll(
									`reply_item${replying_to.timeline_transaction_id}`
								)
							}}
						>
							<Text>
								<Text fontWeight='bold' fontSize='sm' color='blackAlpha.700'>
									{replying_to.log_asset_first_name}
								</Text>
							</Text>
							<Text
								whiteSpace='pre-line'
								fontSize='sm'
								dangerouslySetInnerHTML={{
									__html: convertPound(
										convertUnicode(unescape(replying_to.content))
									),
								}}
							/>
						</Box>
					)}
					<Text
						textAlign='left'
						alignSelf='flex-start'
						whiteSpace='pre-line'
						fontSize='14px'
						color={rmBotScores?.length > 0 ? 'brand.900' : 'brand.900'}
						textDecoration={rmBotScores?.length > 0 ? 'underline' : ''}
						cursor={rmBotScores?.length > 0 ? 'pointer' : ''}
						onClick={() => {
							if (rmBotScores?.length > 0) onOpen()
						}}
						dangerouslySetInnerHTML={{
							__html: convertPound(convertUnicode(unescape(content))),
						}}
					/>
					<Text fontSize='10px' alignSelf='flex-start' mb={0}>
						{timelineDateFormat(getUTCtoIST(datetime_log))}
					</Text>
				</VStack>
			</Flex>
		</>
	)
}

export default Message
